.Default-button {
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.Default-button.active{
  background-color: #226b87;
  color:white;
}


.Default-button:active {
  background-color: #d0d0d0;
}
