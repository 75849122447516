.App-component {
  border: 1px solid;
  margin: 10px;
  margin-bottom: 50px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.App-content {
  display: flex;
  justify-content: center;
  padding: 20px;
}
